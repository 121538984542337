<template>
  <fieldset class="user-form--personal-info">
    <legend>
      <i class="fas fa-user-circle accent--text"></i>&nbsp;
      <b>Personal</b>
    </legend>

    <div class="form--row">
      <label for="firstName" class="grow">
        <span :class="requiredField('firstName')">First Name</span>
        <input
          :disabled="error !== null"
          @input="emitFormData"
          class="wide"
          name="firstName"
          type="text"
          v-model="form.firstName"
          placeholder="e.g. Jane"
        />
      </label>

      <hr class="divider divider--vertical" />

      <label for="lastName" class="col-50">
        <span :class="requiredField('lastName')">Last Name</span>
        <input
          :disabled="error !== null"
          @input="emitFormData"
          class="wide"
          name="lastName"
          type="text"
          v-model="form.lastName"
          placeholder="e.g. User"
        />
      </label>
    </div>

    <!-- User pronouns -->
    <label for="user-pronouns">
      <span class="h6">Preferred pronoun</span>

      <select
        name="user-pronouns"
        v-model="form.proNouns"
        :disabled="error !== null"
        @change="emitFormData"
      >
        <option :value="null">Select one:</option>
        <option v-for="p in Pronouns" :key="p" :value="p">
          {{ p }}
        </option>
      </select>
    </label>

    <!-- User country, timezone -->
    <div class="form--row">
      <auto-complete
        label="Country"
        :invalid="invalidFields.includes('country')"
        :options="Countries"
        :value="selectedCountry"
        @input="onCountrySelect"
        abbr-key="code"
        class="grow"
        display-key="name"
        placeholder="Search for a Country"
      />

      <hr class="divider divider--vertical" />

      <time-zone-picker
        class="col-50"
        required
        zoom-time-zones
        :invalid="invalidFields.includes('timeZone')"
        :show-label-icon="false"
        :value="form.timeZone"
        @input="onTimezoneSelect"
      />
    </div>
  </fieldset>
</template>

<script>
/* eslint-disable no-undef */
import { DateTime } from "luxon";
import { COUNTRIES, Pronouns } from "@/utilities";
import FormsMixin from "./mixins/forms.mixin";
import AutoComplete from "./AutoComplete";
import TimeZonePicker from "./TimeZonePicker";

export default {
  name: "UserFormPersonalInfo",

  components: { TimeZonePicker, AutoComplete },

  mixins: [FormsMixin],

  data: () => ({
    Countries: COUNTRIES,
    form: { country: "US", timeZone: DateTime.local().zoneName },
    Pronouns
  }),

  computed: {
    selectedCountry() {
      if (this.form.country) {
        return this.Countries.find(({ code }) => code === this.form.country);
      }

      return null;
    },
    requiredFields() {
      return [
        "firstName",
        "lastName",
        "username",
        "country",
        "proNouns",
        "timeZone"
      ];
    }
  },

  methods: {
    onCountrySelect({ code: country }) {
      this.appendFormData({ country });
      return this.emitFormData();
    },

    onTimezoneSelect({ text: timeZone }) {
      this.appendFormData({ timeZone });
      return this.emitFormData();
    }
  }
};
</script>

<style lang="scss">
@media screen and (max-width: 600px) {
  .user-form--personal-info {
    .form--row {
      flex-direction: column;
    }

    .form--row label {
      width: $parent;
    }
  }
}
</style>
