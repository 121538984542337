<template>
  <form
    autocomplete="new-password"
    id="user-form"
    class="column form-component"
    @submit.prevent="createOrUpdateUser"
  >
    <loader v-if="loading" :loading-message="loadingMessage" />

    <p>Fields marked with (<span class="error--text">*</span>) are required.</p>

    <!-- Personal Info -->
    <user-form-personal-info
      v-if="showSection('Personal Info')"
      :form-data="form"
      @change="appendFormData"
    />

    <!-- Acct Info -->
    <user-form-account-info
      v-if="showSection('Account Info')"
      :form-data="form"
      :disable-role="disableRole"
      :hide-role="registerUser || !isAdminUser"
      @change="appendFormData"
    />

    <!-- User Bio (Instructors) -->
    <user-form-bio
      v-if="showUserBio"
      class="slide-down-fade-in"
      :form-data="form"
      @change="appendFormData"
    />

    <!-- Notifications Opt-In -->
    <user-form-notifications
      v-if="showSection('Notifications')"
      :form-data="form"
      @change="appendFormData"
    />

    <hr class="divider" />

    <!-- Form controls -->
    <div class="form--row" v-if="!confirmUserDelete">
      <input
        type="submit"
        :disabled="!formIsValid"
        style="margin: 0; padding: 0"
        :class="{
          disabled: error,
          'button success': registerUser,
          'button--outline': !error && !registerUser
        }"
        :value="submitButtonText"
      />

      <input
        v-if="showDeleteAction"
        @click.prevent="confirmUserDelete = true"
        type="button"
        class="outline error--text h6"
        style="margin: 0; padding: 0; text-align: center"
        value="Delete User"
      />
    </div>

    <modal-component
      title="Confirm User Deletion"
      :confirmationText="`Delete ${form.username}'s account`"
      :visible="confirmUserDelete"
      @confirm="deleteUser"
      @close="confirmUserDelete = false"
    >
      <h3 class="grey--text">
        Are you sure you would like to delete
        <b>{{ form.username }}</b
        >?
      </h3>

      <p>This action cannot be undone. Please review the user details below:</p>

      <ul
        class="content"
        style="border: 1rem solid #eee; box-shadow: none; margin: 1rem 0"
      >
        <li><b>Full Name:</b> {{ form.firstName }} {{ form.lastName }}</li>
        <li><b>Username:</b> {{ form.username }}</li>
        <li><b>User Email:</b> {{ form.email }}</li>
        <li><b>User Timezone:</b> {{ form.timeZone }}</li>
      </ul>
    </modal-component>

    <p v-if="validationError || error" class="error--text">
      {{ validationError || error }}
    </p>
  </form>
</template>

<script>
/* eslint-disable no-undef */
import {
  createUser,
  deleteUser,
  getUserById,
  registerUser,
  updateUser
} from "../models/user";
import { COUNTRIES, TIMEZONES, Pronouns } from "@/utilities";
import { extractFormFields, invalidFormMessage } from "../helpers/form.helpers";
import {
  USER_PROFILE_SECTIONS,
  isTeacher,
  isAdminOrTeacher
} from "../models/permissions";
import FormsMixin from "./mixins/forms.mixin";
import Loader from "./Loader.vue";
import PermissionsMixin from "./mixins/permissions.mixin";
import state from "../state";
import UserFormAccountInfo from "./UserForm.AccountInfo.vue";
import UserFormBio from "./UserForm.Bio.vue";
import UserFormNotifications from "./UserForm.Notifications.vue";
import UserFormPersonalInfo from "./UserForm.PersonalInfo.vue";
import ModalComponent from "./ModalComponent.vue";

const reset = () => ({
  role: "Student",
  country: "US",
  timeZone: "America/New_York"
});

export default {
  components: {
    Loader,
    UserFormPersonalInfo,
    UserFormAccountInfo,
    UserFormNotifications,
    UserFormBio,
    ModalComponent
  },
  name: "UserForm",
  mixins: [FormsMixin, PermissionsMixin],
  props: {
    disableRole: Boolean,
    registerUser: Boolean,
    section: {
      type: [String, Array],
      default: "All",
      validate: val =>
        Array.isArray(val)
          ? val.every(USER_PROFILE_SECTIONS.includes)
          : USER_PROFILE_SECTIONS.includes(val)
    }
  },
  data: () => ({
    Countries: COUNTRIES,
    Timezones: TIMEZONES,
    Pronouns,
    confirmUserDelete: false,
    error: null,
    form: reset(),
    loading: true,
    loadingMessage: "Fetching data...",
    user: null,
    validationError: null
  }),
  computed: {
    requiredFields() {
      const fields = [
        "firstName",
        "lastName",
        "username",
        "email",
        "role",
        "country",
        "timeZone"
      ];

      return fields;
    },
    showDeleteAction() {
      return this.isAdminUser && this.userId !== this.activeUser.id;
    },
    showUserBio() {
      return this.section === "All"
        ? isAdminOrTeacher(this.form)
        : this.showSection("User Bio");
    },
    submitButtonText() {
      if (this.error) return " ... ";
      if (this.registerUser) return "Create account";
      const action = !this.userId ? "Create" : "Update";
      return `${action} User`;
    },
    userId() {
      const { id } = this.user || {};
      const { userId } = this.$route.params;
      return id || (userId && Number(userId)) || null;
    }
  },
  watch: {
    "$route.path": function onRouteChange() {
      this.form = reset();
      this.user = null;
      this.clearErrors();
      if (this.$route.params.userId) {
        this.fetchUserForRoute();
      }
    }
  },
  async mounted() {
    if (this.formData) {
      document.title = `Users • Edit User • ${APP_ORG} Admin`;
      this.user = this.formData;
      this.hydrate(this.formData);
      this.stopLoading();
    } else if (this.$route.params.userId) this.fetchUserForRoute();
    else {
      document.title = `Users • Create User • ${APP_ORG} Admin`;
      this.stopLoading();
    }
  },
  methods: {
    appendFormData(data) {
      this.form = { ...this.form, ...data };
      this.clearErrors();
    },

    async createOrUpdateUser() {
      // Validate
      if (!this.formIsValid) {
        return (this.validationError = invalidFormMessage(
          this.form,
          this.requiredFields
        ));
      }

      try {
        const { imageFile, fileExtension } = this.form;
        const imageData = { imageFile, fileExtension };
        const creatingUser = !this.userId && this.$route.name !== "MySettings";
        const verb = creatingUser ? "Creating" : "Updating";
        this.startLoading(`${verb} user ...`);

        if (this.registerUser) {
          const res = await registerUser(this.form);
          if (res.message) throw res;
        } else if (creatingUser) {
          await createUser(this.form, imageData);
        } else {
          const data = { ...this.form, id: this.userId };
          const updated = await updateUser(data, imageData, this.startLoading);
          state.user(updated);
        }
        // redirect on 'create user' -- or notify parent with data
        if (["CreateUser", "EditUser"].includes(this.$route.name)) {
          return this.$router.push({ name: "ListUsers" });
        }

        this.$emit("updated", this.form);
        this.loading = false;
      } catch (error) {
        this.onFormError(error);
      }
    },

    async deleteUser() {
      this.confirmUserDelete = false;
      this.startLoading(`Deleting ${this.form.username} ...`);

      try {
        await deleteUser(this.form.id);
        this.startLoading(`Deleted ${this.form.username}'s account`);

        // Redirect to users list
        setTimeout(() => this.$router.push({ name: "ListUsers" }), 2000);
      } catch (error) {
        this.onFormError(error);
      }
    },

    async fetchUserForRoute() {
      // Init as route
      const { userId } = this.$route.params;
      try {
        this.startLoading("Fetching User ...");
        this.user = await getUserById(userId);
        this.appendFormData(this.user);
        this.stopLoading();
        document.title = `Users • User Details • ${APP_ORG} Admin`;
      } catch (error) {
        this.onFormError(error);
      }
    },

    initializeForm() {
      const src = this.formData || reset();
      this.user = { ...src };
      this.form = extractFormFields(src, this.requiredFields);
    },

    isTeacher,

    showSection(sectionName) {
      return this.section === "All" || this.section.includes(sectionName);
    }
  }
};
</script>
