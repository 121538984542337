<template>
  <label for="classImageFile" class="col-33">
    <span :class="required ? requiredField('imageFile') : 'h6'">
      <i class="accent--text fas fa-image"></i>
      <span>&nbsp;{{ label }}</span>
    </span>

    <input
      type="file"
      accept="image/*"
      name="classImage"
      @input="setImageFile($event.target)"
    />
  </label>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";

export default {
  name: "UserFormImage",

  mixins: [FormsMixin],

  props: {
    label: { type: String, default: "User Image" },
    required: Boolean
  },

  computed: {
    requiredFields() {
      return ["imageFile", "fileExtension"];
    }
  }
};
</script>
