<template>
  <fieldset class="user-form-bio">
    <legend>
      <i class="fas fa-address-card accent--text"></i>&nbsp;
      <b>Image and Bio</b>
    </legend>

    <user-profile-image
      v-if="userImage"
      :image-src="userImage"
      :image-alt="`${form.name}'s profile picture`"
      :subscriber="formData && formData.stripeSubActive"
    />

    <user-form-image
      class="wide"
      :label="userImageLabel"
      :required="isAdminOrTeacherData"
      @change="onImageData"
    />

    <!-- Bio (teachers/practitioners only) -->
    <event-form-description :form-data="form" @change="appendAndEmit" />
  </fieldset>
</template>

<script>
/* eslint-disable no-undef */
import { isAdminOrTeacher } from "../models/permissions";
import FormsMixin from "./mixins/forms.mixin";
import EventFormDescription from "./EventForm.Description.vue";
import UserFormImage from "./UserForm.Image.vue";
import UserProfileImage from "./UserProfileImage.vue";

export default {
  name: "UserFormBio",

  components: { UserFormImage, UserProfileImage, EventFormDescription },

  mixins: [FormsMixin],

  props: { disableRole: Boolean, hideRole: Boolean },

  computed: {
    isAdminOrTeacherData() {
      return isAdminOrTeacher(this.formData || {});
    },

    requiredFields() {
      if (this.isAdminOrTeacherData) {
        return this.formData.userImage
          ? ["userImage", "description"]
          : ["imageFile", "fileExtension", "description"];
      }
      return [];
    },

    userImage() {
      return Boolean(this.form) && this.form.userImage;
    },

    userImageLabel() {
      let lbl = `${this.userImage ? "Change" : "Add"} User Image`;
      return `${lbl} (Instructors only)`;
    }
  },

  methods: {
    onImageData(data) {
      this.appendFormData(data);
      this.emitFormData();
    }
  }
};
</script>
