<template>
  <fieldset class="user-form--account-info">
    <legend>
      <i class="fas fa-address-card accent--text"></i>&nbsp;
      <b>Account</b>
    </legend>

    <!-- User username, email -->
    <div class="form--row">
      <label class="grow">
        <span :class="requiredField('username')">Username</span>
        <input
          autocomplete="username"
          :disabled="error !== null"
          @input="emitFormData"
          type="text"
          v-model="form.username"
          placeholder="Enter username (lowercase)"
          pattern="^\d*[a-z]\d*[^\n]*"
          title="Username in lowercase alphanumeric characters"
        />
      </label>

      <hr class="divider divider--vertical" />

      <label class="col-50">
        <span :class="requiredField('email')">Email</span>
        <input
          :disabled="error !== null"
          @input="emitFormData"
          type="email"
          v-model="form.email"
          placeholder="jane.user@company.org"
          title="User's in lowercase alphanumeric characters"
        />
      </label>
    </div>

    <!-- Password -->
    <div class="form--row">
      <label class="grow">
        <span :class="requiredField('password')">Password</span>
        <input
          autocomplete="new-password"
          :disabled="error !== null"
          @input="emitFormData"
          type="password"
          v-model="form.password"
          placeholder="Enter password"
        />
      </label>

      <hr class="divider divider--vertical" />

      <label class="col-50">
        <span :class="requiredField('confirmPassword')">Confirm Password</span>
        <input
          autocomplete="new-password"
          :disabled="error !== null"
          @input="emitFormData"
          :aria-invalid="form.confirmPassword !== form.password"
          type="password"
          v-model="form.confirmPassword"
          placeholder="Confirm password"
        />
      </label>
    </div>

    <!-- User role + Employee -->
    <div class="form--row">
      <!-- User Role -->
      <label
        for="user-role"
        class="grow"
        :class="{ flex: disableRole }"
        v-if="!hideRole"
      >
        <span :class="`${requiredField('role')} no-shrink`">
          <i class="fas fa-lock grey-light--text" v-if="disableRole"></i>
          Role
        </span>

        <span v-if="disableRole" class="list-item list-item--label grey--text">
          {{ form.role }}
        </span>

        <select
          name="user-role"
          v-else
          v-model="form.role"
          class="grow"
          :disabled="error !== null"
          @change="emitFormData"
        >
          <option v-for="role in roles" :key="role" :value="role">
            {{ role }}
          </option>
        </select>
      </label>

      <hr class="divider divider--vertical" />

      <!-- Employee Boolean -->
      <label
        class="col-50 column"
        for="isOrgEmployee"
        @click.stop="appendAndEmit({ employee: !form.employee })"
        v-if="isAdminUser"
      >
        <hr
          class="divider"
          v-if="['CreateUser', 'EditUser'].includes($route.name)"
        />

        <span
          :class="`inline ${disableRole ? 'checkbox' : 'list-item-'}-label`"
        >
          <template v-if="!disableRole">
            <input
              type="checkbox"
              name="isOrgEmployee"
              v-model="form.employee"
            />

            <hr class="divider divider--vertical" />
          </template>

          <span>
            <i class="fas fa-lock error--text" />&nbsp;
            <strong>{{ appOrgName }}</strong>
            Employee
          </span>
        </span>
      </label>
    </div>
  </fieldset>
</template>

<script>
/* eslint-disable no-undef */
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "UserFormAccountInfo",

  mixins: [FormsMixin, PermissionsMixin],

  props: { disableRole: Boolean, hideRole: Boolean },

  computed: {
    requiredFields() {
      const fields = ["username", "email", "role", "description"];
      const { password, confirmPassword, employee } = this.form;
      const includePasswordFields =
        Boolean(password || confirmPassword) ||
        ["CreateUser", "RegisterUser"].includes(this.$route.name);

      if (includePasswordFields) {
        fields.push("password", "confirmPassword");
      }

      if (typeof employee !== "boolean") {
        fields.push("employee");
      }

      return fields;
    }
  }
};
</script>

<style lang="scss">
@media screen and (max-width: 600px) {
  .user-form--account-info {
    .form--row {
      flex-direction: column;
    }

    .form--row label {
      width: $parent;
    }
  }
}
</style>
