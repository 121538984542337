<template>
  <fieldset class="flex">
    <legend>
      <i class="fas fa-bell-on accent--text"></i>
      Notifications
    </legend>

    <label
      class="form--row"
      @click.stop="appendAndEmit({ emailOptIn: !form.emailOptIn })"
    >
      <span class="h6 flex">
        <input
          :disabled="error !== null"
          type="checkbox"
          :value="form.emailOptIn"
        />
        <hr class="divider divider--vertical" />
        Send Email Notifications
      </span>
    </label>

    <hr class="divider divider--vertical" />

    <label
      class="col-50 form--row"
      @click.stop="appendAndEmit({ mobileOptIn: !form.mobileOptIn })"
    >
      <span class="h6 flex">
        <input
          :disabled="error !== null"
          type="checkbox"
          :value="form.mobileOptIn"
        />
        <hr class="divider divider--vertical" />
        Send Mobile Notifications
      </span>
    </label>
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";

export default {
  name: "UserFormNotifications",

  mixins: [FormsMixin],

  computed: {
    requiredFields() {
      return ["emailOptIn", "mobileOptIn"];
    }
  }
};
</script>
